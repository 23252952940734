.screenshot-section {

    .screenshot-selection-display {
        background: #fff;
        height: 429px;
        max-width: 100%;
        border: 1px solid #eee;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 429px;
            cursor: pointer;
        }
    }

    .screenshot-list {
        div:first-child {
            margin-left: 0px !important;
        }

        .screenshot-selection-item {
            width: 107px;
            height: 107px;
            overflow: hidden;
            background-color: #F6F6F6;
            border: 2px solid #F6F6F6;
            margin-left: 10px;
            cursor: pointer;
            margin-top: 5px;

            img {
                width: auto;
                height: auto;
                max-width: 107px;
                max-height: 107px;
            }
        }

        .screenshot-selection-item-active {
            border: 2px solid #078FCA !important;
        }

        .screenshot-selection-item:hover {
            border: 2px solid #078FCA;
        }
    }
}

.screenshot-fullscreen-overlay {
    position: fixed !important;
    //pointer-events: none !important;
    z-index: 1500 !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;

    background: black;
    opacity: 0.7;
}

.screenshot-fullscreen {
    position: fixed !important;
    //pointer-events: none !important;
    z-index: 1501 !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.attachment-section {

    .attachment-item {
        background: #F6F6F6;
        padding: 10px;
        display: flex;
        align-items: center;
        border-radius: 7px;
    }

    .attachment-image-preview {
        background: white;
        border-radius: 7px;
        //margin-right: 10px;
        width: 80px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        background-repeat: no-repeat;
        //background-size: contain;
        background-size: 100% auto;
        background-position: 50%;
    }

    .attachment-icon {
        background: white;
        //margin-right: 10px;
        //padding: 10px 20px;
        border-radius: 7px;
        width: 80px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.link-highlight:hover {
    font-weight: var(--font-weight-medium);
}

.screenshot-upload-section {
    border: 1px dashed var(--voyce-main-color);
    background: var(--voyce-main-color-lighter);
    border-radius: 7px;

    height: 5rem;

    margin-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.screenshot-upload-section-dragover {
    background: var(--voyce-main-color-light);
}