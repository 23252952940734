.concept-bg {
    background: var(--voyce-main-color);
}

.concept-bg-light {
    background: var(--voyce-main-color-light);
}

.concept-bg-lighter {
    background: var(--voyce-main-color-lighter);
}

.bg-insight {
    background: var(--voyce-color-insight-lighter);
    border: 1px solid var(--voyce-color-insight);
    box-shadow: inset 0px -3px 0px var(--voyce-color-insight);
    border-radius: 5px;
}

.bg-insight:hover {
    background: var(--voyce-color-insight-light) !important;
}

.bg-problem {
    background: var(--voyce-color-problem-lighter);
    border: 1px solid var(--voyce-color-problem);
    box-shadow: inset 0px -3px 0px var(--voyce-color-problem);
    border-radius: 5px;
}

.bg-problem:hover {
    background: var(--voyce-color-problem-light) !important;
}

.bg-solution {
    background: var(--voyce-color-solutions-lighter) !important;
    color: var(--voyce-color-solutions) !important;

    border: 1px solid var(--voyce-color-solutions);
    box-shadow: inset 0px -3px 0px var(--voyce-color-solutions);
    border-radius: 5px;
}

.bg-solution:hover {
    background: var(--voyce-color-solutions-light) !important;
}


// ICON
.icon-regular-size {
    width: 16px !important;
    height: 21px !important;
}

.icon-medium-size {
    width: 24px !important;
    height: 23px !important;
}

.icon-bright {
    color: white;
}

.icon-bright svg {
    fill: white;
}

.processing-overlay {
    position: absolute;
    //background: rgba(246 247 248 / 80%);
    background: var(--font-color-fade);
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.bg-light-grey {
    background: #F5F5F5 !important;
}