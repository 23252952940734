.wd-region{
    width: var(--workspace-card-section-width);
}
.wd {
    width: 100%;
    max-width: 875px;    
}

.workspace-display {
    padding: 0.85rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: white;
    border-radius: 7px;
    border-left: 0.25rem solid var(--workspace-main-color);
    width: 100%;
    //max-width: 700px;
    color: var(--workspace-main-color);

    .concept-link {
        color: var(--workspace-main-color);
    }

    .workspace-saving {
        top: 0;
        left: -0.25rem;
        border-radius: 7px;
    }

    .counter-section {
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        column-gap: 10px;
    }    
}

.wd-group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
}


.wd-insight {
    background: var(--voyce-color-insight-lighter);
    border: 1px solid var(--voyce-color-insight);
    box-shadow: inset 0px -3px 0px var(--voyce-color-insight);
}

.wd-insight:hover {
    background: var(--voyce-color-insight-light) !important;
}

.wd-problem {
    background: var(--voyce-color-problem-lighter);
    border: 1px solid var(--voyce-color-problem);
    box-shadow: inset 0px -3px 0px var(--voyce-color-problem);
}

.wd-problem:hover {
    background: var(--voyce-color-problem-light) !important;
}

.wd-solution {
    background: var(--voyce-color-solutions-lighter) !important;
    color: var(--voyce-color-solutions) !important;

    border: 1px solid var(--voyce-color-solutions);
    box-shadow: inset 0px -3px 0px var(--voyce-color-solutions);
}

.wd-solution:hover {
    background: var(--voyce-color-solutions-light) !important;
}

.workspace-add-region {
    border: 2px dashed #C6C6C6;
    border-radius: 7px;

    svg {
        fill: var(--voyce-main-color);
    }
}

.workspace-add-region:hover {
    background: #f6f7f8;
}

.wk-team-section {
    background: #EDEFF1;
    border-radius: 7px;    
    padding: 5px;
}

.wk-team-section:hover {
    background: rgba($color: #EDEFF1, $alpha: 0.6);
}

.wk-member-display {
    background-color: white;
    border-radius: 7px;
    margin-top: 10px;
    padding: 20px;
    align-items: center;
}

.workspace-link {
    text-decoration: none;
}

.workspace-link:hover {
    color: white;
}

.wk-team-remove-link {
    color: var(--voyce-main-color);
    text-decoration: none;
}

.wk-team-remove-link:hover {
    color: var(--voyce-main-color);
    //text-decoration: underline;
    cursor: pointer;
}