[data-theme="insight"] {
    --voyce-main-color: var(--voyce-color-insight);
    --voyce-main-color-light: var(--voyce-color-insight-light);
    --voyce-main-color-lighter: var(--voyce-color-insight-lighter);
    --voyce-main-color-bright: var(--voyce-color-insight-bright);
    --voyce-main-color-dark: var(--voyce-color-insight-dark);
    --voyce-main-color-darker: var(--voyce-color-insight-darker);
    //color: #078FCA;
    --font-color-theme: var(--voyce-main-color);
}

[data-theme="problem"] {
    --voyce-main-color: var(--voyce-color-problem);
    --voyce-main-color-light: var(--voyce-color-problem-light);
    --voyce-main-color-lighter: var(--voyce-color-problem-lighter);
    --voyce-main-color-bright: var(--voyce-color-problem-bright);
    --voyce-main-color-dark: var(--voyce-color-problem-dark);
    --voyce-main-color-darker: var(--voyce-color-problem-darker);
    //color: #785BCA;
    --font-color-theme: var(--voyce-main-color);
}

[data-theme="solution"] {
    --voyce-main-color: var(--voyce-color-solutions);
    --voyce-main-color-light: var(--voyce-color-solutions-light);
    --voyce-main-color-lighter: var(--voyce-color-solutions-lighter);
    --voyce-main-color-bright: var(--voyce-color-solutions-bright);
    --voyce-main-color-dark: var(--voyce-color-solutions-dark);
    --voyce-main-color-darker: var(--voyce-color-solutions-darker);

    //color: #35B769;
    --font-color-theme: var(--voyce-main-color);
}

[data-theme="workspace"] {
    --voyce-main-color: var(--voyce-color-insight);
    --voyce-main-color-bg: linear-gradient(90.04deg, #078FCA 3.34%, #785BCA 96.39%);
    ;
}

// voyce-dark-overrides
[data-theme="dark"] {

    --bs-body-color: #f4eeff !important;
    //color: #B3BEC1 !important;
    --font-color-primary: #f4eeff;
    //--font-color-primary: #fff;
    --font-color-secondary: #f4eeff;

    --dark-color-light: #2F3E61;
    --voyce-white: rgba(39, 38, 44, .7);
    --bs-body-bg: rgba(39, 38, 44);
    // [data-theme="insight"]{ 
    //     --voyce-main-color: red !important;
    // }

    .btn-light {
        background-color: rgba(39, 38, 44, .7) !important;
        border-color: rgba(39, 38, 44, .5) !important;
        color: var(--font-color-primary) !important;
    }


    .bg-white {
        background-color: #141B2D !important;
    }

    .voyce-card-draggable {
        background-color: #141B2D;
        color: white;
    }

    // .bg-header {
    //     background: #006D9B !important;
    //     //background: #0e4e6a !important;
    //     //color: white;
    // }

    .layout-main-content {
        background-color: #141B2D;
    }

    .layout-main-content .main-content {
        background-color: #141B2D;
        //background-color: #343a40;
    }

    .search-bar-bg {
        background-color: #2F3E61 !important;
    }

    .voyce-card-sm {
        background-color: var(--dark-color-light) !important;
    }

    [data-theme="insight"] {
        --voyce-color-insight: #006D9B !important;
        --voyce-color-insight-light: #006D9B;
        --voyce-color-insight-lighter: #006D9B;
        --font-color-theme: var(--font-color-primary);


        .voyce-card {
            //background-color: #0e4e6a !important;
            background-color: #2F3E61;
        }

        // .search-bar-bg {
        //     background-color: #2F3E61 !important;
        // }

        // .main-content {
        //     background-color: #1f3640 !important;
        //     border-color: #1f3640 !important;
        // }
    }

    [data-theme="problem"] {
        //--voyce-color-problem: #5A429E !important;
        --voyce-color-problem: #4e3692 !important;        
        --voyce-color-problem-light: #4e3692;
        --voyce-color-problem-lighter: #4e3692;
        --font-color-theme: var(--font-color-primary);

        .voyce-card {
            //background-color: #0e4e6a !important;
            background-color: var(--dark-color-light);
        }

        .voyce-card-sm {
            background-color: var(--dark-color-light);
        }

        // .voyce-card {
        //     background-color: #423468 !important;
        //     border-color: #5A429E !important;
        //     //background-color: #2F3E61;
        // }

        // .search-bar-bg {
        //     background-color: #423468 !important;
        // }

        // .main-content {
        //     background-color: #231e31 !important;
        //     border-color: #231e31 !important;
        // }
    }

    [data-theme="solution"] {
        --voyce-color-solutions: #318653 !important;
        --voyce-color-solutions-light: #318653;
        --voyce-color-solutions-lighter: #318653;
        --font-color-theme: var(--font-color-primary);

        .voyce-card {
            //background-color: #266640 !important;
            background-color: var(--dark-color-light);
        }

        .voyce-card-sm {
            background-color: var(--dark-color-light);
        }

        // .search-bar-bg {
        //     background-color: #266640 !important;
        // }

        // .main-content {
        //     background-color: #29342e !important;
        //     border-color: #29342e !important;
        // }
    }

    .search-bar {
        color: white !important;
    }

    .vc-list-group {
        color: white !important;
    }

    //  .bg-header a {
    // //    color: var(--voyce-main-color);
    // //252526
    // }

    .voyce-card {
        background-color: var(--voyce-main-color-dark);
        color: white;
        //border-left: 0.25rem solid white;
    }
}