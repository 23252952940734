.voyce-display-mod {
    --voyce-card-background: var(--voyce-main-color-lighter) !important;
    --voyce-card-background-hover: var(--voyce-main-color-lighter) !important;
}

.voyce-card-size {
    width: var(--voyce-card-width) !important;
}

.card-display {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    width: var(--voyce-card-width);
}

.voyce-card-default {
    border-left: var(--voyce-card-borderleft-size) solid var(--voyce-main-color);
}

.voyce-card-mobile-handle {
    background: var(--voyce-main-color);
    border-radius: 7px 0 0 7px;

    width: 12px;
    display: var(--voyce-card-handle-display);
    align-items: center;
}

.voyce-card {
    //margin-left: 5rem;
    //margin-right: 5rem;
    //padding: 1rem;
    //padding-bottom: 0.5rem;
    // margin-top: 1.25rem;
    // margin-bottom: 1.25rem;
    background-color: var(--voyce-card-background);
    border-radius: 7px;
    //border-left: 0px solid var(--voyce-main-color);
    width: var(--voyce-card-width);
    color: var(--voyce-main-color);
    cursor: grab;
}

.disable-selection-events {
    -webkit-touch-callout: none;
    /* Safari */
    -webkit-user-select: none;
    /* Chrome */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.voyce-card:hover {
    background-color: var(--voyce-card-background-hover);
}

// .voyce-card-full-size {
//     width: 400px;
// }

.voyce-card-sm {
    padding: 0.5rem;
    padding-left: 0.8rem;
    background: var(--voyce-main-color-lighter);
    border-radius: 7px;
    border-left: 0.25rem solid var(--voyce-main-color);
    margin-bottom: 0.5rem;
}

.voyce-card-sm:hover {
    background-color: var(--voyce-main-color-bright);
}

.voyce-card:active {
    cursor: grabbing !important;
}

.concept-link-viewer {
    .voyce-card {
        width: 100% !important;
        cursor: default !important;
    }
}


.voyce-card {
    .vc-options {
        display: none;
    }

    .vc-actions {
        padding-top: 0.5rem;
    }
}

.voyce-card:hover {
    .vc-options {
        cursor: pointer;
        display: block;
    }
}

.concept-link {
    color: var(--voyce-main-color);
    text-decoration: none;
}

.concept-link:hover {
    color: var(--voyce-main-color);
    //text-decoration: underline;
    cursor: pointer;
}