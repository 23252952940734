.range-selector {
    width: 100%;

    .slider {
        -webkit-appearance: none;
        /* Override default CSS styles */
        appearance: none;
        width: 100%;
        /* Full-width */
        height: 20px;
        /* Specified height */
        background-color: #d3d3d3;
        //background: linear-gradient(to right, var(--voyce-main-color) 0%, var(--voyce-main-color) 50%, #d3d3d3 50%, #d3d3d3 100%);
        /* Grey background */
        outline: none;
        /* Remove outline */
        //opacity: 0.7;
        /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: .2s;
        /* 0.2 seconds transition on hover */
        transition: opacity .2s;

        border-radius: 10px;
    }

    //  this.style.background = 'linear-gradient(to right, #82CFD0 0%, #82CFD0 ' + value + '%, #fff ' + value + '%, white 100%)'

    // .slider:hover {
    //     //opacity: 1;
    //     /* Fully shown on mouse-over */
    // }

    /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        /* Override default look */
        appearance: none;
        width: 27px;
        /* Set a specific slider handle width */
        height: 27px;
        /* Slider handle height */
        background: white;
        border: 1px solid var(--voyce-main-color);
        /* Green background */
        cursor: pointer;

        border-radius: 50%;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);

        /* Cursor on hover */
    }

    .slider::-moz-range-thumb {
        width: 27px;
        /* Set a specific slider handle width */
        height: 27px;
        /* Slider handle height */
        background: white;
        border: 1px solid var(--voyce-main-color);
        /* Green background */
        cursor: pointer;
        /* Cursor on hover */
        border-radius: 50%;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);

    }
}



// .range-selector .slider {
//     -webkit-appearance: none;
//     /* Override default CSS styles */
//     appearance: none;
//     width: 100%;
//     /* Full-width */
//     height: 25px;
//     /* Specified height */
//     background: #d3d3d3;
//     /* Grey background */
//     outline: none;
//     /* Remove outline */
//     opacity: 0.7;
//     /* Set transparency (for mouse-over effects on hover) */
//     -webkit-transition: .2s;
//     /* 0.2 seconds transition on hover */
//     transition: opacity .2s;

// }

// .range-selector .slider:hover {
//     opacity: 1;
//     /* Fully shown on mouse-over */
// }

// /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
// .range-selector .slider::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     /* Override default look */
//     appearance: none;
//     width: 25px;
//     /* Set a specific slider handle width */
//     height: 25px;
//     /* Slider handle height */
//     background: #04AA6D;
//     /* Green background */
//     cursor: pointer;
//     /* Cursor on hover */
// }

// .range-selector .slider::-moz-range-thumb {
//     width: 25px;
//     /* Set a specific slider handle width */
//     height: 25px;
//     /* Slider handle height */
//     background: #04AA6D;
//     /* Green background */
//     cursor: pointer;
//     /* Cursor on hover */
// }