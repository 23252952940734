
.voyce-card-draggable-layer {
    position: fixed !important;
    pointer-events: none !important;
    z-index: 100 !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.voyce-card-draggable {

    display: "inline-block" !important;
    //border:1px solid red;
    height: max-content;
    width: max-content;    
    //cursor: grabbing !important;
    //pointer-events:  !important;

    //   transform: rotate(-1deg) !important;
    //   -webkit-transform: rotate(-1deg) !important;
    //  -webkit-transform-origin: left;

    //max-width: 100px;
    opacity: 0.8;
}


.dragtest {
    * {
        //    cursor: grabbing !important;
        cursor: grabbing !important;
        // cursor: -moz-grabbing;
        // cursor: -webkit-grabbing;

        user-select: none // without this I get the text cursor in Safari 
    }
}

.dragtest-over {
    * {
        //    cursor: grabbing !important;
        //cursor: grab !important;
        //cursor: alias !important;
        // cursor: -moz-grabbing;
        // cursor: -webkit-grabbing;

        user-select: none // without this I get the text cursor in Safari 
    }
}


// body {

//     // you can conditionally set this, only when dragging
//     * {
//         cursor: grabbing !important;
//         user-select: none // without this I get the text cursor in Safari 
//     }

// }