.alert-fixed {
    position: fixed !important;
    top: 1rem;
    left: 0px;
    width: 100%;
    z-index: 9999;
    border-radius: 0px;
    pointer-events: none !important;
}

.alert-concept-success {
    --bs-alert-color: #232323 !important;
    --bs-alert-bg: #6BDD99 !important;
    border: 0 !important;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    height: 3rem;
    width: max-content;
    min-width: 350px;

    svg {
        fill: #232323;
    }
}

.alert-concept-error {
    svg {
        fill: var(--bs-danger-text-emphasis);
    }
}


.alert-help-us {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;

    .alert-body {
        background: var(--workspace-main-color);
        color: var(--font-color-light);
        padding: 10px 20px;

        border-radius: 70px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .alert-body:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25) inset, 0 0 20px 2px rgba(0, 0, 0, 0.25);
    }
}