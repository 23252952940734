.dashboard-container {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 576px) {
    .dashboard-section {
        .add-newconcept-section {
            grid-template-columns: 1fr 1fr !important;
        }
    }
}

.dashboard-section {
    width: calc(var(--voyce-card-width) + 200px);
    max-width: var(--voyce-card-width-lg);
    //background-color: red;
    // border: 1px solid black;

    .display-header {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .add-newconcept-section {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 10px;

        // div {
        //     background-color: red;
        // }

        .item-section {
            //padding: 0.2rem;
            padding: 0px 20px;
            //padding-left: 1rem;
            //padding-right: 1.25rem;
            background-color: white;

            border: 1px solid #DDDDDD;
            border-radius: 10px;
            width: 100%;
            color: var(--workspace-main-color);
            text-decoration: none;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .item-section:hover {
            background: #F6F7F8;
            color: var(--workspace-main-color);
        }
    }

    .recent-feedback-section {
        background: #F6F7F8;
        border: 1px solid #DDD;
        border-radius: 10px;
        width: 100%;


    }

    .recent-events-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }


    // .recent-comment-card {
    //     background-color: #FFF;
    //     box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.11) !important;
    // }

    // overrides
    .voyce-card-sm {
        background-color: #FFF;
        box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.11) !important;
    }

    .dashboard-card-override {
        .concept-link {
            color: black !important;
            font-size: 1rem;
            font-weight: 500;
        }

        .link-insight {
            color: var(--voyce-color-insight) !important;
        }
    }

    .comment-card-override {
        border: 0 !important;
        padding: 0.6rem;
        padding-left: 1.1rem;
    }

    .feedback-graph-section {
        border: 1px solid #DDD !important;
    }
}

[data-theme="dark"] {
    .dashboard-container {
        //background: #6d4db2;
        border-left: 1px solid rgba(39, 38, 44, .7);
        ;
        //linear-gradient(180deg,#6c4999,#6d4db2) !important;
    }

    //rgba(39,38,44,.7)
    .recent-feedback-section {
        background-color: var(--dark-color-light);
        color: black !important;

        .voyce-card-sm {

            // .concept-link {
            //    // color: black !important;
            // }

            background-color: rgba(39, 38, 44, .7) !important;
            //   background-color: red;
            //background-color: var(--dark-color-light);
        }

        .feedback-graph-section {
            background-color: rgba(39, 38, 44, .7);
            color: var(--font-color-primary);
            border-color: rgba(39, 38, 44) !important;

            .feedback-positive {
                background-color: #318653 !important;
            }
        }
    }

    .add-newconcept-section {
        .item-section {
            background: rgba(39, 38, 44, .7);
            color: var(--font-color-primary);
        }

        .item-section:hover {
            background: rgba(39, 38, 44, .9);
            color: var(--font-color-primary);
        }
    }

    .dashboard-card-override {
        .concept-link {
            color: #f4eeff !important;
        }
    }
}