.other a {
    color: #777;
    text-decoration: underline;
    font-size: 14px;
}

.other ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.ltr {
    text-align: left;
}

.rtl {
    text-align: right;
}

.editor-default {
    line-height: 1.8rem;
}

.editor-container {
    //background: blue;
    margin: 5px auto 20px auto;
    //border-radius: 2px;
    //max-width: 600px;
    color: #000;
    position: relative;
    //line-height: 20px;
    font-weight: 400;
    text-align: left;
    //border-top-left-radius: 10px;
    //border-top-right-radius: 10px;
    border-radius: 10px;

    border: 1px solid #ced4da;
}

.editor-container-focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.editor-input {
    min-height: 150px;
    resize: none;
    //font-size: 15px;
    caret-color: rgb(5, 5, 5);
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px !important;
    caret-color: #444;
}

.editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
}

.editor-paragraph {
    margin: 0 0 15px 0;
    position: relative;
}

.tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    line-height: 14px;
}

pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

pre::-webkit-scrollbar-thumb {
    background: #999;
}

.debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 10px 0;
    margin: auto;
    display: flex;
}

.debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
}

.debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: #fff;
    font-size: 12px;
}

.debug-timetravel-panel-button:hover {
    text-decoration: underline;
}

.debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 12px;
    top: 10px;
    right: 15px;
    position: absolute;
    background: none;
    color: #fff;
}

.debug-timetravel-button:hover {
    text-decoration: underline;
}

.emoji {
    color: transparent;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
}

.emoji-inner {
    padding: 0 0.15em;
}

.emoji-inner::selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
}

// .emoji.happysmile {
//     //background-image: url(./images/emoji/1F642.png);
// }

.editor-code {
    font-family: monospace;
    background: #f6f7f8;
    //color: var(--voyce-main-color);
    display: block;
    width: 100%;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    padding: 10px 10px;
    line-height: 1.2rem;
    margin-bottom: 10px;
}

.toolbar {
    display: flex;
    margin-bottom: 1px;
    background: #f6f7f8;
    padding: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    vertical-align: middle;

}

.toolbar button.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
    cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
    margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
    opacity: 0.2;
}

.toolbar button.toolbar-item.active {
    //background-color: rgba(223, 232, 250, 0.3);
    background-color: #e9ecef;
}

.toolbar button.toolbar-item.active i {
    opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
    background-color: #eee;
}

.toolbar .divider {
    width: 1px;
    background-color: #ced4da;
    margin: 5px 4px;
}

.toolbar select.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 70px;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
}

.toolbar select.code-language {
    text-transform: capitalize;
    width: 130px;
}

.toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
}

.toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
}

// .toolbar i.chevron-down {
//     margin-top: 3px;
//     width: 16px;
//     height: 16px;
//     display: flex;
//     user-select: none;
// }

// .toolbar i.chevron-down.inside {
//     width: 16px;
//     height: 16px;
//     display: flex;
//     margin-left: -25px;
//     margin-top: 11px;
//     margin-right: 10px;
//     pointer-events: none;
// }

// i.chevron-down {
//     background-color: transparent;
//     background-size: contain;
//     display: inline-block;
//     height: 8px;
//     width: 8px;
//     //background-image: url(images/icons/chevron-down.svg);
// }

.link-editor {
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 400px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;

    .link-cancel {
        background-image: url(../../images/ref/x-circle-fill.svg);
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        width: 35px;
        vertical-align: -0.25em;
        margin-right: 28px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
    }

    .link-confirm {
        background-image: url(../../images/ref/check-circle-fill.svg);
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        width: 35px;
        vertical-align: -0.25em;
        margin-right: 2px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
    }

    .link-view {
        display: block;
        width: calc(100% - 16px);
        margin: 8px 12px;
        padding: 8px 12px;
        border-radius: 15px;
        font-size: 15px;
        //color: #050505;
        border: 0;
        outline: 0;
        position: relative;
        font-family: inherit;
        word-wrap: break-word;
        word-break: break-all;

        a {
            display: block;
            width: calc(100% - 24px);
        }
    }
}

.link-editor .link-input {
    display: block;
    //width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
    width: calc(100% - 75px);
}

.link-editor div.link-edit {
    background-image: url(../../images/ref/pencil-fill.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.link-editor .link-input a {
    color: rgb(33, 111, 219);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
    text-decoration: underline;
}

.link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
}

.link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eee;
}

.link-editor .button i,
.actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
}

i.undo {
    background-image: url(../../images/ref/arrow-counterclockwise.svg);
}

i.redo {
    background-image: url(../../images/ref/arrow-clockwise.svg);
}

.icon.code {
    background-image: url(../../images/ref/code.svg);
}

i.bold {
    background-image: url(../../images/ref/type-bold.svg);
}

i.italic {
    background-image: url(../../images/ref/type-italic.svg);
}

i.underline {
    background-image: url(../../images/ref/type-underline.svg);
}

i.strikethrough {
    background-image: url(../../images/ref/type-strikethrough.svg);
}

i.code {
    background-image: url(../../images/ref/code.svg);
}

i.bullet-list {
    background-image: url(../../images/ref/list-ul.svg);
}

i.numeric-list {
    background-image: url(../../images/ref/list-ol.svg);
}

i.link {
    background-image: url(../../images/ref/link-45deg.svg);
}

i.quote {
    background-image: url(../../images/ref/chat-square-quote.svg);
}

i.check-list {
    background-image: url(../../images/ref/list-check.svg);
}

.editor-text-bold {
    font-weight: bold;
}

.editor-text-italic {
    font-style: italic;
}

.editor-text-underline {
    text-decoration: underline;
}

.editor-text-strikethrough {
    text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
}

.editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.editor-list-listitem-checked,
.editor-list-listitem-unchecked {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 24px;
    padding-right: 24px;
    list-style-type: none;
    outline: none;
}

.editor-list-listitem-checked {
    text-decoration: line-through;
}

.editor-list-listitem-unchecked:before,
.editor-list-listitem-checked:before {
    content: '';
    width: 16px;
    height: 16px;
    top: 6px;
    left: 0;
    cursor: pointer;
    display: block;
    background-size: cover;
    position: absolute;
}

.editor-list-listitem-unchecked[dir='rtl']:before,
.editor-list-listitem-checked[dir='rtl']:before {
    left: auto;
    right: 0;
}

.editor-list-listitem-unchecked:focus:before,
.editor-list-listitem-checked:focus:before {
    box-shadow: 0 0 0 2px #a6cdfe;
    border-radius: 2px;
}

.editor-list-listitem-unchecked:before {
    border: 1px solid #999;
    border-radius: 2px;
}

.viewer-default {

    .editor-list-listitem-checked:after,
    .editor-list-listitem-unchecked:after,
    .editor-list-listitem-unchecked,
    .editor-list-listitem-checked {
        cursor: text !important;
    }

    .editor-list-listitem-checked:before {
        cursor: text !important;
        border: 1px solid var(--voyce-main-color-light) !important;
        background-color: var(--voyce-main-color-light) !important;
    }

    .editor-list-listitem-unchecked:before {
        cursor: text !important;
        border: 1px solid rgb(206, 208, 212) !important;
        background-color: rgb(206, 208, 212, 0.4) !important;
    }

    .editor-list-listitem-checked:focus:before,
    .editor-list-listitem-unchecked:focus:before {
        box-shadow: none !important;
    }
}

.editor-list-listitem-checked:before {
    border: 1px solid var(--voyce-main-color);
    border-radius: 2px;
    background-color: var(--voyce-main-color);
    background-repeat: no-repeat;
}

.editor-list-listitem-checked:after {
    content: '';
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    display: block;
    top: 10px;
    width: 3px;
    left: 7px;
    right: 7px;
    height: 6px;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
}