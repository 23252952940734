/* elements defaults*/
input[type=checkbox] {
    cursor: pointer;
}

/* enable absolute positioning */
.vc-input-group {
    position: relative;
}

.vc-input-group .vc-icon {
    position: absolute;
    pointer-events: none;
    width: 30px;
    background: transparent;
}


.vc-input-group-right input {
    padding-right: 30px;
}

.vc-input-group-right .vc-icon {
    right: 0px;
}

.vc-input-group-left input {
    padding-left: 30px;
}

.vc-input-group-left .vc-icon {
    left: 0px;
}

.keyword-search input {
    border-radius: 20px !important;
    background: var(--voyce-white);
    width: 220px;
    padding-left: 1.2rem;
}

.keyword-search .vc-icon {
    padding-top: 6px !important;
}

//leaving here for now
.display-lines {
    white-space: pre-line;
    word-wrap: break-word;
    word-break: break-word;
}

// .text-effect:hover{
//     background: white;
// }

.no-resize {
    resize: none;
}

.text-effect {
    overflow: hidden;
}

.text-effect:focus {
    background: var(--voyce-white) !important;
}

.text-display-group {
    margin-top: 8px;
}

.text-display-group .display-label {
    width: 82px;
}

.color-picker-component {
    background: #FF0000;
    border-radius: 35px;
    border: 1px solid white;

    .color-picker-input {
        opacity: 0;
        display: block;
        width: 25px;
        height: 25px;
        border: none;
    }
}