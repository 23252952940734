.fw-xsmall-400 {
    font-size: var(--font-size-xsmall);
    font-weight: var(--font-weight-regular);
}

.fw-xsmall-500 {
    font-size: var(--font-size-xsmall);
    font-weight: var(--font-weight-medium);
}

.fw-small-400 {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
}

.fw-small-500 {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
}

.fw-small-600 {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semi-bold);
}

.fw-small-700 {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
}

.fw-reg-400 {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
}

.fw-reg-500 {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-medium);
}

.fw-reg-700 {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-bold);
}

.fw-med-400 {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-regular);
}

.fw-med-500 {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
}

.fw-med-600{
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-semi-bold);
}

.fw-med-700 {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
}

.fw-large-400 {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-regular);
}

.fw-large-500 {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
}

.fw-large-700 {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
}

.fw-xlarge-400 {
    font-size: var(--font-size-xlarge);
    font-weight: var(--font-weight-regular);
}

.fw-xlarge-700 {
    font-size: var(--font-size-xlarge);
    font-weight: var(--font-weight-bold);
    line-height: var(--font-size-xlarge-line);
}

.fw-xxlarge-700 {
    font-size: var(--font-size-xxlarge);
    font-weight: var(--font-weight-bold);
}

.fw-xxlarge-900 {
    font-size: var(--font-size-xxlarge);
    font-weight: var(--font-weight-bolder);
}

// font colors 
.fw-concept {
    color: var(--voyce-main-color);
}

.fw-dark {
    color: var(--font-color-primary);
}

.fw-secondary {
    color: var(--font-color-secondary);
}

.fw-color-light {
    color: var(--font-color-light);
}

.fw-fade {
    color: var(--font-color-fade);
}

.fw-insight {
    color: var(--voyce-color-insight);
}

.fw-problem {
    color: var(--voyce-color-problem);
}

.fw-solution {
    color: var(--voyce-color-solutions);
}

//font-style
.fw-i {
    font-style: italic;
}