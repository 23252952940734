.tab-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;

    .tab-link {
        background-color: var(--voyce-main-color-lighter);
        width: 100%;

        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 7px 7px 0 0;

        border: 0px;
        border-bottom: 1px solid var(--voyce-main-color);
        color: var(--voyce-main-color);
    }

    .active {
        border: 1px solid var(--voyce-main-color);
        border-bottom: 0px;
        background-color: white;
        color: var(--font-color-primary);
    }
}