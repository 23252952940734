* {
    --side-menu-size: 294px;

    --voyce-card-width: 100%;
    --voyce-card-width-lg: 800px;
    --workspace-card-section-width: 50vw;

    // --voyce-card-handle-display: flex;
    // --voyce-card-borderleft-size: 0;
    --voyce-card-handle-display: none;
    --voyce-card-borderleft-size: 0.25rem;
}

// @media screen and (min-width: 1024px) {
//     * {
//         //--voyce-card-width: 600px !important;
//         //--side-menu-size: 294px !important;
//         //width: 550px;
//     }
// }

@media screen and (min-width: 1310px) {
    * {
        --side-menu-size: 20% !important;
        --voyce-card-width: var(--voyce-card-width-lg) !important;
    }
}

.header-content {
    padding-left: 1rem;
}

.display-full {
    display: inline;
}

.display-mobile {
    display: none;
}

.display-tab {
    display: none;
}

@media screen and (max-width: 1310px) {

    .display-full {
        display: none !important;
    }

    .display-tab {
        display: inline !important;
    }
}

@media screen and (max-width: 1130px) {
    .d-sidebar {
        display: none !important;
    }

    .header-content {
        padding-left: 0px !important;
    }

    .display-mobile {
        display: inline !important;
    }

    * {
        --voyce-card-handle-display: flex !important;
        --voyce-card-borderleft-size: 0 !important;
        --workspace-card-section-width: 70vw !important;
    }
}

@media screen and (max-width: 768px) {
    .header-content {
        display: none !important;
    }

    * {
        --workspace-card-section-width: 92vw !important;
    }

    // .search-bar {
    //     &:not(.show) {
    //         height: 0px;
    //         overflow: hidden;
    //         //display: none;
    //     }
    // }
}