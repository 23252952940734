.heatmap-chart-y-axis {
    transform: rotate(180deg);

    writing-mode: vertical-lr;
    width: 10px;
    display: flex;
    margin-top: 10px;
    margin-bottom: 5px;
}

.heatmap-chart {
    display: flex;
    width: 200px;

    --item-bg-color: #5ACF6E;

    .viewer-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        background-color: var(--item-bg-color);
        color: white;
        border: 1px solid var(--voyce-main-color-lighter);
    }

    .item-selected {
        background-color: white !important;
        color: black !important;
        font-weight: 700;
    }

    .item-1 {
        --item-bg-color: #5ACF6E;
    }

    .item-2 {
        --item-bg-color: #00B6BC;
    }

    .item-3 {
        --item-bg-color: #F5B659;
    }

    .item-4 {
        --item-bg-color: #F46E4A;
    }

    .item-5 {
        --item-bg-color: #F53951;
    }

    svg {
        fill: black !important;
    }
}

[data-theme="solution-map"] {
    .heatmap-chart {
        .item-1 {
            --item-bg-color: #F53951 !important;
        }

        .item-2 {
            --item-bg-color: #F46E4A !important;
        }

        .item-3 {
            --item-bg-color: #F5B659 !important;
        }

        .item-4 {
            --item-bg-color: #00B6BC !important;
        }

        .item-5 {
            --item-bg-color: #5ACF6E !important;
        }
    }
}


.implementation-level {
    color: white;
    padding: 5px 1rem;
    border-radius: 15px;
}

.implvl-1 {
    background: #5ACF6E;
}

.implvl-2 {
    background: #00B6BC;
}

.implvl-3 {
    background: #F5B659;
}

.implvl-4 {
    background: #F46E4A;
}

.implvl-5 {
    background: #F53951;
}