.feedback-section {

    .feedback-settings-section {
        background: var(--voyce-main-color-light);
        border-radius: 10px;
    }

    .feedback-share-container {
        display: grid;
        grid-template-columns: auto min-content;
    }

    .feedback-share-url {
        background: white;
    }

    .feedback-action {
        background: white;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;

        flex: 1;
        min-width: 100px;

        svg {
            fill: #078FCA;
        }
    }

    .feedback-action:hover {
        background: #f6f7f8;
    }

    .feedback-toggle {
        display: grid;
        grid-template-columns: min-content auto;
    }
}

@media screen and (max-width: 576px) {
    .feedback-graph-section {
        grid-template-columns: min-content 1fr !important;
        padding-bottom: 10px;

        .feedback-bar {
            grid-column: span 2;

            div {
                margin-top: 5px;
                margin-bottom: 5px;
            }

            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}

.feedback-graph-section {
    background-color: white;
    border-radius: 7px;

    display: grid;
    grid-template-columns: min-content 150px auto;

    div {
        display: flex;
        align-items: center;
    }

    .feedback-bar {

        .feedback-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 7px;

            div {
                display: flex;
                align-items: center;
                justify-content: center;

                height: 100%;
                width: 50%;
            }
        }

        .feedback-positive {
            width: 50%;
            border-radius: 7px 0px 0px 7px;
            background-color: #35B769;
        }

        .feedback-negative {
            border-radius: 0 7px 7px 0;
            background-color: #888888;
        }

    }
}