.comment-display {
    transition: opacity 0.3s;
    opacity: 1;

    background: white;
    border-radius: 7px;
}

.comment-display-conceptmod {
    background: var(--voyce-main-color-light);
}

.comment-display-saving {
    opacity: 0.2;
}