.line-selection {
    display: flex;
    height: 2.5rem;
    cursor: pointer;
    margin-bottom: 2px;
    border-radius: 7px;
    border: 1px solid transparent;
}

.line-selection-focus {
    background-color: var(--voyce-main-color-lighter);
    // #bootstrap-workaround -> Fixing bug on hover, leaving box-shadow behind on leave - much more visible on dark mode
    box-shadow: 0 0 1px var(--voyce-main-color-lighter);


    .hover-item {
        display: block !important;

    }
}

.line-selection:hover {
    background-color: var(--voyce-main-color-lighter);
    // #bootstrap-workaround -> Fixing bug on hover, leaving box-shadow behind on leave - much more visible on dark mode
    box-shadow: 0 0 1px var(--voyce-main-color-lighter);
}

// .line-selection:focus {
//     background-color: var(--voyce-main-color-lighter);
//     // #bootstrap-workaround -> Fixing bug on hover, leaving box-shadow behind on leave - much more visible on dark mode
//     box-shadow: 0 0 1px var(--voyce-main-color-lighter);
// }

.enable-on-hover {
    .hover-item {
        display: none;
    }
}

.enable-on-hover:hover {
    .hover-item {
        display: block !important;
    }

    .hide-on-hover {
        display: none !important;
    }
}

.enable-on-hover:focus {
    .hover-item {
        display: block !important;
    }
}