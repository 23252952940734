.voyce-logo svg {
    fill: var(--font-color-primary) !important;
}

.text-concept {
    color: var(--voyce-main-color) !important;
}

@media screen and (min-width: 768px) {

    .layout-main-content {
        background-color: #EDEFF1;
        min-height: calc(100vh - 5.5rem);
    }
}

.loading-content {
    min-height: calc(100vh - 5.5rem);
    width: 100%;

    // default -80 due the main sidebar
    margin: -80px;
}

.layout-concept-full {
    .loading-content {
        margin: 0px !important;
    }
}

.search-bar-bg {
    background-color: #f6f7f8;
}

.search-section-size {
    min-width: 294px;
    max-width: 394px;
    width: var(--side-menu-size);
    max-height: calc(100vh - 5.5rem);
    overflow: auto;
}

.layout-main-sidebar {
    grid-area: sidebar;

    @media screen and (min-width: 576px) {
        grid-area: sidebar;
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        display: block !important;
        height: 100vh;
        //padding-left: 0.25rem;
        //margin-left: -0.25rem;
        //overflow: auto;    
    }
}

.main-sidebar {
    fill: #B3BEC1;
}

.main-sidebar .concept-link {
    color: #B3BEC1 !important;
    text-decoration: none !important;
}

.main-sidebar a:hover div {
    font-weight: 700;
}

.main-sidebar svg {
    fill: #B3BEC1;
}

.main-sidebar-active div {
    color: var(--font-color-primary) !important;
    font-weight: 700 !important;
}

.main-sidebar-active svg {
    fill: var(--voyce-main-color);
}

.offcanvas-body {
    .main-sidebar-active div {
        color: var(--voyce-main-color-light) !important
    }
}

// header
.bg-header {
    background: var(--voyce-main-color) !important;
}

.bg-header-home {
    background: var(--voyce-main-color-bg) !important;
}

.bg-voyce-dark {
    background-color: var(--voyce-main-color-darker) !important;
}

.layout-main-content .main-content {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 5.5rem);
    //color: var(--bs-gray-dark);

    //background-color: #141B2D;
    //background-color: #343a40;
}

.vc-editor {
    label {
        display: flex;
        color: var(--voyce-main-color) !important;
    }

    label span {
        margin-left: 0.5rem;
    }

    svg {
        fill: var(--voyce-main-color);
    }

    hr {
        margin: 1.5rem 0;
        opacity: 0.1;
    }
}

.concept-editor {
    padding-left: 88px !important;
    padding-right: 88px !important;
}

.vc-list-group {
    fill: var(--font-color-primary);
    color: var(--font-color-primary);
}

.vc-list-group .drag-receive {
    border: 1px dashed var(--voyce-main-color) !important;
    //border-radius: 5px;
}

.vc-list-group .vc-item {
    display: flex;
    height: 2.5rem;
    cursor: pointer;
    margin-bottom: 2px;
    border-radius: 7px;
    border: 1px solid transparent;
}

.vc-list-group .vc-item:hover {
    background-color: var(--voyce-main-color-lighter);
    // #bootstrap-workaround -> Fixing bug on hover, leaving box-shadow behind on leave - much more visible on dark mode
    box-shadow: 0 0 1px var(--voyce-main-color-lighter);
}


// .vc-list-group .drag-receive .active {
//     background: var(--voyce-main-color-light);
// }

.vc-list-group .vc-item span {
    margin-left: 0.5rem;
}

.vc-list-group .active {
    background-color: var(--voyce-main-color) !important;
    border-radius: 7px;
    fill: white !important;
    color: white !important;
}

.vc-list-group .active .fw-secondary {
    color: white !important;
}

.concept-saving {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {

    // 50% {
    //   opacity: 0;
    // }
    50% {
        border-color: #ff0000;
    }
}


.vc-custom-scroll {
    overscroll-behavior: contain;
}

.vc-custom-scroll::-webkit-scrollbar {
    width: 12px;
    /* for vertical scrollbars */
    height: 12px;
    /* for horizontal scrollbars */
}

// .vc-custom-scroll::-webkit-scrollbar-track {
//     // nothing defined    
// }

.vc-custom-scroll::-webkit-scrollbar-thumb {
    background: var(--voyce-main-color-lighter);
    border-radius: 7px;
}

.icon-color {
    fill: #B3BEC1 !important;
}

.modal-border {
    border: 0px !important;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));
}

.cursor-pointer {
    cursor: pointer;
}

.vc-custom-scroll-dark {
    overscroll-behavior: contain;
}

.vc-custom-scroll-dark-small::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}

.vc-custom-scroll-dark::-webkit-scrollbar {
    width: 12px;
    /* for vertical scrollbars */
    height: 12px;
    /* for horizontal scrollbars */
}

// .vc-custom-scroll::-webkit-scrollbar-track {
//     // nothing defined    
// }

.vc-custom-scroll-dark::-webkit-scrollbar-thumb {
    //background: var(--voyce-main-color-light);
    background: var(--voyce-scrollbar-color);
    border-radius: 7px;
}

.header-full-nav {
    padding-top: 15px;
    background: var(--voyce-main-color-dark);
    overflow: hidden;
    //border: 1px solid var(--voyce-main-color-dark);    

    .header-workspace {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-regular);
        color: var(--font-color-fade);
    }
}

.header-separator {
    border-left: 2px solid var(--voyce-main-color-dark);

    svg {
        fill: var(--voyce-main-color-dark);
    }
}

.sz-fit {
    width: fit-content;
}