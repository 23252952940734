body {

    /*Font definition */
    --font-color-primary: #232323;
    --font-color-secondary: #888888;
    --font-color-light: #EDEFF1;
    --font-color-fade: rgba(255, 255, 255, 0.55);
    --font-color-theme: var(--voyce-main-color);


    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-bolder: 900;

    --font-size-xsmall: 0.8rem;
    --font-size-small: 0.875rem;
    --font-size-regular: 1rem;
    --font-size-medium: 1.1rem;
    --font-size-large: 1.25rem;
    --font-size-xlarge: 1.5rem;
    --font-size-xxlarge: 2rem;

    --font-size-xlarge-line: 1.8rem;


    /* color definition */
    --voyce-main-color: #078FCA;
    --voyce-main-color-bg: #078FCA;
    --voyce-main-color-light: #D4EBF4;
    --voyce-main-color-lighter: #E6F4F9;
    --voyce-main-color-bright: #F9FDFF;
    --voyce-main-color-dark: #0580B6;
    --voyce-main-color-darker: #0e4e6a;

    --voyce-color-insight: #078FCA;
    --voyce-color-insight-light: #D4EBF4;
    --voyce-color-insight-lighter: #E6F4F9;
    --voyce-color-insight-bright: #F9FDFF;
    --voyce-color-insight-dark: #0580B6;
    --voyce-color-insight-darker: #0e4e6a;

    --voyce-color-problem: #785BCA;
    --voyce-color-problem-light: #DDD6EF;
    --voyce-color-problem-lighter: #F4F0FF;
    --voyce-color-problem-bright: #FAF8FF;
    --voyce-color-problem-dark: #5E35AA;
    --voyce-color-problem-darker: #423468;

    --voyce-color-solutions: #35B769;
    --voyce-color-solutions-lighter: #E4F5EB;
    --voyce-color-solutions-light: #D1EDDC;
    --voyce-color-solutions-bright: #F4FCF7;
    --voyce-color-solutions-dark: #2EA45D;
    --voyce-color-solutions-darker: #266640;

    --workspace-main-color: #232323;


    --voyce-scrollbar-color: #d7d7d7;
    // cards    
    --voyce-card-background: white;
    --voyce-card-background-hover: var(--voyce-main-color-bright);

    --voyce-white: white;

    overflow: hidden;
}