.dark-icon {
    fill: #232323;

    svg {
        fill: #232323 !important;
    }
}

.light-icon {
    fill: #c6c7c8;

    svg {
        fill: #c6c7c8 !important;
    }
}

.white-icon {
    fill: white;

    svg {
        fill: white !important;
    }
}

.grey-icon {
    fill: var(--font-color-secondary);

    svg {
        fill: var(--font-color-secondary);
    }

}

.insight-icon {
    fill: var(--voyce-color-insight);

    svg {
        fill: var(--voyce-color-insight);
    }
}

.problem-icon {
    fill: var(--voyce-color-problem);

    svg {
        fill: var(--voyce-color-problem);
    }
}

.solution-icon {
    fill: var(--voyce-color-solutions);

    svg {
        fill: var(--voyce-color-solutions);
    }
}