.concept-tag-container {
    display: flex;
    flex-wrap: wrap;
}

.tag-group {
    margin-right: 0.25rem;
    margin-bottom: 3px;
}

.vc-tag-header {
    background-color: var(--voyce-main-color);
    border-radius: 5px 5px 0px 0px;
}

.concept-link-to-existing-container {
    height: 510px;
    width: 600px;
    cursor: default;
    padding: 0;
    border: 0;
    z-index: 2000;

    border-radius: 7px;
    background: #f6f7f8;

    .concept-link-header {
        border-radius: 7px 7px 0px 0px;
    }
}


@media screen and (max-width: 1130px) {
    .concept-link-to-existing-container {
        height: 100% !important;
        width: 100% !important;
        border-radius: 0 !important;

        .concept-link-header {
            border-radius: 0 !important;
        }
    }
}

.drop-container {
    width: 430px;
    height: 450px;
    cursor: default !important;
    padding: 0 !important;
    border: 0 !important;
    z-index: 2000 !important;
}

.drop-container-link {
    height: 510px !important;
    width: 600px !important;

    .drop-body {
        height: 315px;
    }
}

.drop-container-keyword-search {
    height: 395px !important;
    width: 600px !important;

    .drop-body {
        height: 315px;
    }
}