.getstarted-content {
    min-width: 350px;
    max-width: 600px;
}

.getstarted-display {
    padding: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: white;

    border: 1px solid #DDDDDD;
    border-radius: 10px;
    width: 100%;
    color: var(--workspace-main-color);
    text-decoration: none;
}


.getstarted-display:hover {
    background: #EDEFF1;
    color: var(--workspace-main-color);
}