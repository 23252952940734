// btn-concept
.btn-concept {
    --bs-btn-bg: var(--voyce-main-color) !important;
    --bs-btn-border-color: var(--voyce-main-color) !important;
    //--bs-btn-border-color: white !important;

    --bs-btn-hover-bg: var(--voyce-main-color-darker) !important;
    --bs-btn-hover-border-color: white !important;

    --bs-btn-active-bg: var(--voyce-main-color-darker) !important;
    --bs-btn-active-border-color: var(--voyce-main-color) !important;

    --bs-btn-disabled-bg: var(--voyce-main-color) !important;
    --bs-btn-disabled-border-color: var(--voyce-main-color) !important;

    font-size: var(--font-size-regular) !important;
    font-weight: var(--font-weight-medium) !important;

    svg {
        fill: var(--bs-btn-color) !important;
    }
}

// btn-concept-outline
.btn-concept-outline {
    --bs-btn-bg: white !important;
    --bs-btn-color: var(--voyce-main-color) !important;
    --bs-btn-border-color: var(--voyce-main-color) !important;

    --bs-btn-hover-bg: var(--voyce-main-color-lighter) !important;
    --bs-btn-hover-color: var(--voyce-main-color) !important;
    --bs-btn-hover-border-color: var(--voyce-main-color) !important;
    //--bs-btn-hover-border-color: var(--voyce-main-color-light) !important;

    --bs-btn-active-bg: var(--voyce-main-color-light) !important;
    --bs-btn-active-color: var(--voyce-main-color) !important;
    --bs-btn-active-border-color: var(--voyce-main-color) !important;
    //--bs-btn-active-color: var(--voyce-main-color-light) !important;
    //--bs-btn-active-border-color: var(--voyce-main-color-light) !important;

    font-size: var(--font-size-regular) !important;
    font-weight: var(--font-weight-medium) !important;

    svg {
        fill: var(--voyce-main-color) !important;
    }
}

.btn-concept-lighter {
    --bs-btn-bg: var(--voyce-main-color-lighter) !important;
    --bs-btn-hover-bg: var(--voyce-main-color) !important;
    --bs-btn-color: var(--voyce-main-color) !important;
    --bs-btn-hover-color: var(--voyce-main-color-lighter) !important;
    --bs-btn-font-weight: var(--font-weight-medium) !important;
    --bs-btn-padding-x: .6rem !important;
    fill: var(--voyce-main-color) !important;
}

.btn-concept-display {
    //background: var(--voyce-main-color-lighter);
    display: flex;
    align-items: center;    
    color: var(--font-color-theme);
    padding: 5px 10px 5px 10px;
    border-radius: 7px;
    font-weight: 500;
    //font-size: 10px;
    font-size: 13px;

    white-space: nowrap;

    fill: var(--voyce-main-color);

    svg {
        fill: var(--voyce-main-color);
    }
}

.btn-concept-lighter:hover svg {
    //background-color: var(--voyce-main-color) !important;
    //color: var(--voyce-main-color-light) !important;
    fill: var(--voyce-main-color-lighter) !important;
}

.btn-concept-light {
    --bs-btn-bg: var(--voyce-main-color-light) !important;
    --bs-btn-border-color: var(--voyce-main-color-light) !important;
    --bs-btn-hover-bg: var(--voyce-main-color) !important;
    --bs-btn-color: var(--voyce-main-color) !important;
    --bs-btn-hover-color: var(--voyce-main-color-light) !important;
    --bs-btn-font-weight: var(--font-weight-medium) !important;
    --bs-btn-padding-x: .6rem !important;
    fill: var(--voyce-main-color) !important;
}

.btn-concept-light:hover svg {
    fill: var(--voyce-main-color-light) !important;
}


//
// .btn-concept-v2 {
//     --bs-btn-color: var(--voyce-main-color) !important;
//     --bs-btn-font-weight: var(--font-weight-regular) !important;
//     --bs-btn-border-color: var(--voyce-main-color) !important;

//     --bs-btn-hover-bg: #D4EBF4 !important;
//     --bs-btn-hover-color: var(--voyce-main-color) !important;
//     --bs-btn-hover-border-color: var(--voyce-main-color) !important;
//     //--bs-btn-hover-border-color: var(--voyce-main-color-light) !important;

//     --bs-btn-active-bg: var(--voyce-main-color) !important;
//     --bs-btn-active-color: var(--voyce-main-color-light) !important;
//     --bs-btn-active-border-color: var(--voyce-main-color-light) !important;
// }

.btn-mod-border {
    --bs-btn-border-color: white !important;
}

.btn-mod {
    box-shadow: inset 0px -3px 0px var(--voyce-main-color);
}

.btn-mod-dark {
    box-shadow: inset 0px -3px 0px var(--voyce-main-color-darker);
}

.btn-folder {
    --bs-btn-color: var(--voyce-main-color) !important;
    --bs-btn-font-weight: var(--font-weight-medium) !important;

    --bs-btn-hover-bg: var(--voyce-main-color) !important;
    --bs-btn-hover-color: var(--voyce-main-color-lighter) !important;

    --bs-btn-active-bg: var(--voyce-main-color) !important;
    --bs-btn-active-color: var(--voyce-main-color-lighter) !important;
}

.btn-xsmall {
    --bs-btn-padding-y: .25rem !important;
    --bs-btn-padding-x: .5rem !important;
    --bs-btn-font-size: var(--font-size-xsmall) !important;
    font-size: var(--font-size-xsmall) !important;
}

.btn-small {
    --bs-btn-padding-y: .3rem !important;
    --bs-btn-padding-x: 0.8rem !important;
    --bs-btn-font-size: var(--font-size-small) !important;
}

.btn-medium {
    width: 170px;
}

.btn-add-concept {
    border: 2px dashed var(--voyce-main-color);
    border-radius: 7px;

    svg {
        fill: var(--voyce-main-color) !important;
    }
}

.btn-add-concept:hover {
    //background: #f6f7f8;
    //background: var(--voyce-main-color-lighter);
    background: #f6f7f8;
}

.btn-mod-tag {
    //--bs-btn-font-weight: 500 !important;
    --bs-btn-color: #999 !important;
}

.btn-mod-createtag {
    width: 120px;
}

.btn-custom-light {
    --bs-btn-border-color: var(--font-color-secondary) !important;
    --bs-btn-hover-border-color: var(--font-color-secondary) !important;

    --bs-btn-color: var(--font-color-secondary) !important;
    --bs-btn-hover-color: var(--font-color-secondary) !important;

    --bs-btn-hover-bg: #EDEFF1;
}

.btn-custom-light:hover {
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.11) !important;
}