.layout-concept-full {
    background: #EDEFF1 !important;
    min-height: calc(100vh - 3rem);

    .main-content {
        width: 100%;
        overflow: auto;
        height: calc(100vh - 3rem);
    }
}

.concept-viewer-container {
    display: grid;
    grid-template-columns: auto 285px;
}

.concept-organize-container {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 15px;
}

.concept-link-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    width: 100%;
}

.concept-link-item-expand {
    grid-column: span 2;
}

.voyce-header-full {
    width: 100%;
}

.concept-fullscreen-display {
    min-height: calc(100vh - 3rem
            /*header*/
            - 13rem);

    padding-top: 15px !important;

    .concept-display-header {
        border: 0;
        color: white;
        border-radius: 7px 7px 0 0;
    }
}

// Mobile overrides
@media screen and (max-width: 768px) {
    .concept-viewer-container {
        grid-template-columns: auto !important;
        height: 100%;
    }

    .alert-help-us {
        display: none !important;
    }

    .screenshot-upload-section {
        display: none;
    }

    .screenshot-upload-section-uploading {
        display: flex !important;
    }

    .concept-organize-container {
        //   flex-wrap: wrap;
        //display: grid !important;
        grid-template-columns: auto !important;
        row-gap: 5px;
    }

    .concept-link-container {
        grid-template-columns: auto !important;
    }

    .voyce-header-full {
        //display: none;
        position: fixed !important;
        bottom: 0;
        height: 3rem;
        border-top: 1px solid var(--voyce-main-color-light);

    }

    .concept-fullscreen-display {
        padding-top: 0px !important;

        .concept-display-header {
            border-radius: 0 !important;
        }
    }

    .concept-tag-container {
        .dropdown-menu {
            width: 100% !important;
            max-width: 430px;
        }
    }

    .concept-editor {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    //.layout-concept-full {
    //      min-height: calc(100vh) !important;
    //      background: var(--voyce-main-color-lighter) !important;

    //     .main-content {
    //         background: var(--voyce-main-color-lighter) !important;
    //     }
    // }
}